import {ApolloError} from '@apollo/client';
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  ChangeEvent,
  useState,
} from 'react';
import {
  Outlet,
  useLocation,
  useParams,
  useOutletContext,
} from 'react-router-dom';

import downArrow from '../../src/assets/icons/arrow-left.svg';
import Notification from '../assets/icons/notification.svg';
import Breadcrumb from '../components/common/Breadcrumb';
import Fab from '../components/common/Fab';
import Modal from '../components/common/Modal';
import Spinner from '../components/common/Spinner';
import TreeNav from '../components/common/TreeNav';
import {
  MerchantFieldsFragment,
  MerchantUpdateInput,
  useMerchantDetailsQuery,
  useProductQuery,
  useRestrictProductMutation,
  useUpdateMerchantMutation,
} from '../generated';
import {merchantReadableStatus} from '../pages/merchants/hooks/useGetMerchants';

type NavTreeType = {
  name: string;
  link: string;
  icon?: ReactNode | string;
  children?: {
    name: string;
    link: string;
  }[];
};

interface Context {
  merchant?: MerchantFieldsFragment;
  merchantId?: string;
  update: (input: MerchantUpdateInput) => Promise<void>;
  reset: () => void;
  loading?: boolean;
  error?: ApolloError;
  dataLoading?: boolean;
}

export const MerchantContext = createContext<Context>({
  update: async () => undefined,
  reset: () => undefined,
});

export const useMerchant = (): Context => useContext(MerchantContext);

const Merchant = () => {
  const {merchantId} = useParams();
  const [merchant, setMerchant] = useState<MerchantFieldsFragment>();
  const [updateMerchant, {error: updateError, loading: updateLoading}] =
    useUpdateMerchantMutation();

  const {data, loading, error} = useMerchantDetailsQuery({
    variables: {
      id: merchantId || '',
    },
    skip: merchantId === '',
  });

  const isLoading = useMemo(
    () => loading || updateLoading,
    [loading, updateLoading]
  );

  const hasError = useMemo(() => error || updateError, [error, updateError]);

  useEffect(() => {
    if (!data || !data.merchant) return;
    setMerchant(data.merchant);
  }, [data]);

  const reset = () => {
    setMerchant(undefined);
  };

  const update = useCallback(
    async (input: MerchantUpdateInput) => {
      if (!merchantId) return;
      await updateMerchant({
        variables: {id: merchantId, merchantUpdateInput: input},
      });
    },
    [merchantId, updateMerchant]
  );

  return (
    <MerchantContext.Provider
      value={{
        merchant,
        merchantId,
        reset,
        update,
        error: hasError,
        loading: isLoading,
        dataLoading: loading,
      }}>
      <MerchantLayout />
    </MerchantContext.Provider>
  );
};

type MyContext = {
  onPageChange: (t: string | 'No Name') => void;
  setShowRestrictProduct?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useMyContext = () => useOutletContext<MyContext>();

const MerchantLayout = () => {
  const {merchantId, productId, reelId} = useParams();
  const location = useLocation();
  const path = location.pathname;
  const {merchant, dataLoading} = useMerchant();
  const [productName, setProductName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showRestrictProduct, setShowRestrictProduct] = useState(false);
  const {data: product} = useProductQuery({
    variables: {
      id: productId || '',
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsButtonEnabled(value.trim().length > 0);
  };

  const [restrictProductMutation] = useRestrictProductMutation();

  const crrrumbs = useMemo(() => {
    const items = [
      {
        name: 'Merchants',
        location: '/merchants',
      },
    ];

    if (path.includes('productDetails')) {
      items.push({
        name: merchant?.name || '',
        location: `/merchants/merchant/view/products/${merchantId || ''}`,
      });
      items.push({
        name: productName || '',
        location: `/merchants/merchant/view/products/productDetails/${
          merchantId || ''
        }/${productId || ''}`,
      });

      if (path.includes('variants')) {
        items.push({
          name: 'Variants',
          location: `/merchants/merchant/view/products/productDetails/${
            merchantId || ''
          }/${productId || ''}/variants`,
        });
      }
    } else if (path.includes('analytic')) {
      items.push({
        name: merchant?.name || '',
        location: `/merchants/merchant/view/reels/${merchantId || ''}`,
      });
      items.push({
        name: 'Reel Analytics',
        location: `/merchants/merchant/view/reels/analytic/${merchantId || ''}`,
      });
    } else {
      items.push({
        name: merchant?.name || '',
        location: `/merchants/merchant/view/products/${merchantId || ''}`,
      });
    }

    return items;
  }, [path, merchant, merchantId, productId, productName]);

  const ProductDetailRoutes: NavTreeType[] = useMemo(() => {
    const base: NavTreeType[] = [
      {
        name: 'Store Details',
        link: '#',
      },
    ];

    if (productId) {
      base.push({
        name: productName,
        link: `/merchants/merchant/view/products/${merchant?.id || ''}`,
        icon: <img src={downArrow} />,
      });
    }

    return base;
  }, [productId, productName, merchant?.id]);

  const ReelAnalyticRoutes: NavTreeType[] = useMemo(() => {
    const base: NavTreeType[] = [
      {
        name: 'Reels',
        link: `/merchants/merchant/view/reels/${merchant?.id || ''}`,
        icon: <img src={downArrow} />,
      },
    ];

    return base;
  }, [merchant?.id]);

  const tree: NavTreeType[] = useMemo(() => {
    if (productId) {
      return ProductDetailRoutes;
    } else if (reelId) {
      return ReelAnalyticRoutes;
    } else {
      return [
        {
          name: 'Store Details',
          link: `/merchants/merchant/view/products/${merchantId || ''}`,
          children: [
            {
              name: 'Location',
              link: `/merchants/merchant/view/location/${merchantId || ''}`,
            },
            {
              name: 'Products',
              link: `/merchants/merchant/view/products/${merchantId || ''}`,
            },
            {
              name: 'Reels',
              link: `/merchants/merchant/view/reels/${merchantId || ''}`,
            },
            {
              name: 'About',
              link: `/merchants/merchant/view/about/${merchantId || ''}`,
            },
          ],
        },
        {
          name: 'Documents',
          link: `/merchants/merchant/documents/${merchantId || ''}`,
        },
        {
          name: 'Operating hours',
          link: `/merchants/merchant/hours/${merchantId || ''}`,
        },
        {
          name: 'Transactions',
          link: `/merchants/merchant/transactions/${merchantId || ''}`,
        },
      ];
    }
  }, [ProductDetailRoutes, ReelAnalyticRoutes, merchantId, productId, reelId]);

  const handlePageChange = (name: string) => {
    setProductName(name);
  };

  if (dataLoading) {
    return (
      <div className="mt-[-100px] flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }
  return (
    <div className="flex basis-full flex-col">
      <div className="flex h-[100px] flex-row items-center px-[30px]">
        <h1 className="flex basis-[24%] text-2xl font-semibold">
          {merchant?.name || '______'}
        </h1>
        <div className="flex basis-[76%] flex-row items-center justify-between">
          <Breadcrumb breadcrumbs={crrrumbs} />
          <div className="flex flex-row items-center">
            <img
              className="h-[20px] w-[20px]"
              src={
                (merchant?.approvalState &&
                  merchantReadableStatus(merchant?.approvalState)[1]) ||
                ''
              }
              alt="merchant status"
            />
            <p className="pl-1 font-medium">
              {merchant?.approvalState &&
                merchantReadableStatus(merchant?.approvalState)[0]}
            </p>
            <Fab className="ml-[30px]" hasAlert>
              <img className="border-1 h-25px] w-[25px]" src={Notification} />
            </Fab>
          </div>
        </div>
      </div>
      <div className="flex h-[calc(100vh-100px)] flex-col font-semibold">
        <div className="flex overflow-y-scroll">
          <TreeNav tree={tree} />
          <div className="flex flex-1 flex-col">
            <Outlet
              context={{
                onPageChange: handlePageChange,
                setShowRestrictProduct,
              }}
            />
          </div>
        </div>
        {showRestrictProduct && (
          <div className=" relative inset-x-0 bottom-0  flex items-center justify-end border-t-2 bg-whiteGreyish pb-2 pr-7 pt-2">
            {product?.product?.restricted ? (
              <>
                <button
                  className=" h-[36px] w-[159px] cursor-pointer select-none items-center  rounded-lg bg-black p-[5px]   text-white transition duration-200 hover:cursor-pointer"
                  onClick={() => setIsModalOpen(true)}>
                  Unrestrict Product
                </button>
                <Modal
                  isVisible={isModalOpen}
                  className="w-[25%]"
                  onClose={() => setIsModalOpen(false)}>
                  <div className="p-3 text-center">
                    <p className="pt-3  text-2xl font-semibold text-black">
                      Unrestrict Product
                    </p>
                    <div>
                      <p className="mb-3 ml-4 mr-4 mt-1 pb-4  text-lg font-medium  text-darkGrey">
                        This action will make the products viewable for all
                        customers
                      </p>
                    </div>

                    <hr />
                  </div>

                  <div className="flex w-full flex-row justify-between  p-4">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className=" px-5  text-lg font-medium text-black  underline">
                      Cancel
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={async () => {
                        await restrictProductMutation({
                          variables: {
                            input: {
                              restricted: !product?.product?.restricted,
                              id: productId || '',
                              reason: '',
                            },
                          },
                        });
                        setIsModalOpen(false);
                      }}
                      className="mr-3 w-[145px] rounded-lg bg-black px-5 py-2.5 text-center text-sm font-medium text-white">
                      Yes, Unrestrict
                    </button>
                  </div>
                </Modal>
              </>
            ) : (
              <>
                <button
                  className=" h-[36px] w-[159px] cursor-pointer select-none items-center  rounded-lg bg-darkRed  p-[5px] text-white transition duration-200 hover:cursor-pointer"
                  onClick={() => setShowModal(true)}>
                  Restrict Product
                </button>
                <Modal
                  isVisible={showModal}
                  className=" w-[45%]"
                  onClose={() => setShowModal(false)}>
                  <div className="p-4 text-center">
                    <p className="font-metropolis m-3 mb-5 pl-[150px] pr-[150px] text-xl font-semibold text-black">
                      Please give a reason for this restriction
                    </p>
                    <div className=" mb-5 pl-4 pr-4">
                      <textarea
                        className="w-[90%] resize-none rounded-[5px] border bg-offWhite p-4"
                        cols={20}
                        rows={5}
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>

                    <hr />
                  </div>
                  <div className="flex w-full flex-row justify-between  p-4">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      onClick={() => setShowModal(false)}
                      className=" text-md  px-5 font-medium text-black  underline">
                      Cancel
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      disabled={!isButtonEnabled}
                      onClick={async () => {
                        await restrictProductMutation({
                          variables: {
                            input: {
                              id: productId || '',
                              restricted: !product?.product?.restricted,
                              reason: inputValue,
                            },
                          },
                        });
                        setShowModal(false);
                        setInputValue('');
                      }}
                      className={`mr-3 w-[80px] rounded-lg px-3 py-2.5 text-center text-sm font-medium text-white ${
                        isButtonEnabled ? 'bg-black' : 'bg-greyish'
                      }`}>
                      Restrict
                    </button>
                  </div>
                </Modal>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Merchant;
