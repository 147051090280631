import {useDebounce, useInputState} from '@cashiaApp/web-components';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';

import filterIcon from '../assets/icons/filter-icon.svg';
import rightArrow from '../assets/icons/outline-right-arrow.svg';
import searchIcon from '../assets/icons/search_icon.svg';
import Button from '../components/common/Button';
import DropDown from '../components/common/DropDown';
import IconButton from '../components/common/IconButton';
import Tabs from '../components/common/Tabs';
import TextInput from '../components/common/TextInput';
import TopBar from '../pages/customers/components/TopBar';
import {Categories} from '../utils/reusablefunctions/transactionOptions';

type TransactionSelectionProps = {
  debouncedSearchTerm: string;
  selectedCategory?: Categories | null;
};
const TransactionSelectionContext = createContext<TransactionSelectionProps>({
  debouncedSearchTerm: '',
});
export const useTransactionSelectionContext = () =>
  useContext(TransactionSelectionContext);

function CustomerTransactionsLayout() {
  const {id} = useParams();
  const {pathname} = useLocation();
  const [selectedFilter, setSelectedFilter] = useState<Categories | null>();

  const [searchTerm, setSearchTerm] = useInputState('');
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const tabs = [
    {name: 'All', link: `/customer/${id || ''}/transactions`},
    {name: 'Successful', link: `/customer/${id || ''}/transactions/successful`},
    {name: 'Pending', link: `/customer/${id || ''}/transactions/pending`},
    {name: 'Failed', link: `/customer/${id || ''}/transactions/failed`},
  ];

  useEffect(() => {
    document.getElementById('top')?.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
    });
  }, [pathname]);

  return (
    <div id="top" className="flex flex-col pt-0">
      <TopBar />
      <h4 className="mb-2 pb-3 pt-6 text-2xl font-semibold leading-6">
        All transactions
      </h4>
      <div className="flex-auto">
        <TextInput
          endAdornment={<img src={searchIcon} className="mr-1 h-5 w-5" />}
          placeholder="Find a transaction..."
          inputStyle="h-6"
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </div>
      {selectedFilter ? (
        <div className="mt-8 flex h-[50px] w-full items-center">
          <div className="flex w-full items-center justify-between">
            <p className="text-xl font-semibold">{selectedFilter}</p>
            <div className="mr-2 flex items-center gap-3">
              <Button
                label="Close"
                text
                onClick={() => setSelectedFilter(null)}
              />
              <FilterTransaction setSelectedFilter={setSelectedFilter} />
            </div>
          </div>
        </div>
      ) : (
        <Tabs
          tabs={tabs}
          rightElement={
            <FilterTransaction setSelectedFilter={setSelectedFilter} />
          }
        />
      )}
      <div className="h-full w-full">
        <TransactionSelectionContext.Provider
          value={{selectedCategory: selectedFilter, debouncedSearchTerm}}>
          <Outlet />
        </TransactionSelectionContext.Provider>
      </div>
    </div>
  );
}

type FilterTransactionProps = {
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<Categories | null | undefined>
  >;
};
const FilterTransaction = ({setSelectedFilter}: FilterTransactionProps) => {
  return (
    <DropDown
      dropDownPosition="right"
      className="right-0 top-7"
      persistent
      actionElement={<IconButton icon={filterIcon} />}>
      <div className="flex w-[300px] flex-col gap-2 rounded-lg bg-white p-5 py-4 text-sm text-gray-700">
        {Object.values(Categories).map((cat, i) => (
          <div
            key={i}
            onClick={() => setSelectedFilter(cat)}
            className="flex cursor-pointer justify-between rounded-lg border-2 border-dividerGrey p-4">
            <p className="text-base	font-medium">{cat}</p>
            <img src={rightArrow} />
          </div>
        ))}
      </div>
    </DropDown>
  );
};

export default CustomerTransactionsLayout;
